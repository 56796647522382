import config from '@/aws-exports';
import { Auth } from 'aws-amplify';
import {
    AdminCreateUserCommand,
    AdminConfirmSignUpCommand,
    AdminEnableUserCommand,
    AdminDisableUserCommand,
    AdminDeleteUserCommand,
    AdminAddUserToGroupCommand,
    AdminRemoveUserFromGroupCommand,
    AdminGetUserCommand,
    AdminListGroupsForUserCommand,
    AdminResetUserPasswordCommand,
    AdminSetUserPasswordCommand,
    AdminUserGlobalSignOutCommand,
    CognitoIdentityProviderClient,
    ListUsersCommand,
    ListGroupsCommand,
    ListUsersInGroupCommand,
    DeliveryMediumType, MessageActionType, AdminListUserAuthEventsCommand, AdminUpdateUserAttributesCommand
} from '@aws-sdk/client-cognito-identity-provider';

/** Cognito Provider Client **/
const cognitoIdentityProviderClient = async () => {
    //https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/index.html

    //https://dev.to/aws-builders/the-guide-to-connect-aws-amplify-to-any-aws-service-2-0-40ne
    const credentials = await Auth.currentCredentials();
    return new CognitoIdentityProviderClient({
        credentials: Auth.essentialCredentials(credentials),
        region: config.aws_cognito_region,
    });
}

export async function adminCreateUser(options) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: options.username,
        UserAttributes: [
            {
                Name: 'email', /* required */
                Value: options.email
            },
        ],
        DesiredDeliveryMediums: [DeliveryMediumType.EMAIL]
    };

    if(options.user_id) {
        params.UserAttributes.push({
            Name: 'custom:user_id',
            Value: options.user_id
        })
    }

    if(options.phone_number) {
        params.UserAttributes.push({
            Name: 'phone_number',
            Value: options.phone_number
        })
    }

    if(options.email_verified) {
        params.UserAttributes.push({
            Name: 'email_verified',
            Value: 'true'
        })
    }

    if(options.suppressEmail) {
        params.MessageAction = MessageActionType.SUPPRESS
    }

    if(options.temporary_password) {
        params.TemporaryPassword = options.temporary_password
    }

    const command = new AdminCreateUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

export async function adminAddUserToGroup(username, group) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
        GroupName: group
    };
    const command = new AdminAddUserToGroupCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

export async function adminRemoveUserFromGroup(username, group) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
        GroupName: group
    };
    const command = new AdminRemoveUserFromGroupCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

export async function adminGetUser(username) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminGetUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}


export async function adminListGroupsForUser(username) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminListGroupsForUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}


// eslint-disable-next-line consistent-return
export async function adminListUsers(nextToken, pagedUsers) {
    const users = pagedUsers || []
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Limit: 60,
    };
    if(nextToken) {
        params.PaginationToken = nextToken
    }

    const command = new ListUsersCommand(params);
    const output = await cognitoIdentityProviderClient().then(client => client.send(command))
    users.push(...output.Users)

    if(output.PaginationToken) {
        await adminListUsers(output.PaginationToken, users)
    }
    return users;
}

export async function adminListGroups() {
    const params = {
        UserPoolId: config.aws_user_pools_id,
    };
    const command = new ListGroupsCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}


export async function adminListUsersInGroup(group, nextToken, pagedUsers) {
    const users = pagedUsers || []
    const params = {
        GroupName: group,
        UserPoolId: config.aws_user_pools_id,
        Limit: 60
    };
    if(nextToken) {
        params.NextToken = nextToken
    }
    const command = new ListUsersInGroupCommand(params);
    const output = await cognitoIdentityProviderClient().then(client => client.send(command))
    users.push(...output.Users)

    if(output.NextToken) {
        await adminListUsersInGroup(group, output.NextToken, users)
    }
    return users;
}

/**
 * Admin Enable User
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminenableusercommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/adminenableusercommandinput.html
 */
export async function adminEnableUser(username) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminEnableUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Disable User
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/admindisableusercommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/admindisableusercommandinput.html
*/
export async function adminDisableUser(username) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminDisableUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Delete User
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/admindeleteusercommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/admindeleteusercommandinput.html
 */
export async function adminDeleteUser(username) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminDeleteUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Resend User Password
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/admincreateusercommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/admincreateusercommandinput.html
 */
export async function adminResendUserInvitation(username) {
    /*  Set to RESEND to send an invitation message to a user that already exists and reset the expiration limit on the user's account. */
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
        MessageAction: MessageActionType.RESEND,
        DesiredDeliveryMediums: [DeliveryMediumType.EMAIL]
    };
    const command = new AdminCreateUserCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Reset User Password
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminresetuserpasswordcommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/adminresetuserpasswordcommandinput.html
 */
export async function adminResetUserPassword(username) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username
    };
    const command = new AdminResetUserPasswordCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Set User Temporary Password
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminsetuserpasswordcommand.html
 * hhttps://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/adminsetuserpasswordcommandinput.html
 */
export async function adminSetUserTemporaryPassword(username, password) {
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
        Password: password,
        Permanent: false
    };
    const command = new AdminSetUserPasswordCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Confirm SignUp
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminconfirmsignupcommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/adminconfirmsignupcommandinput.html
 */
export async function adminConfirmSignUp(username) {
    //https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-email-phone-verification.html
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username
    };
    const command = new AdminConfirmSignUpCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Update User Attributes
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminupdateuserattributescommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/adminupdateuserattributescommandinput.html
 */
export async function adminUpdateUserAttributes(username, attributes) {
    //https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-email-phone-verification.html
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
        UserAttributes: attributes
    };
    const command = new AdminUpdateUserAttributesCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}

/**
 * Admin Sign Out User
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/classes/adminuserglobalsignoutcommand.html
 * https://docs.aws.amazon.com/AWSJavaScriptSDK/v3/latest/clients/client-cognito-identity-provider/interfaces/adminuserglobalsignoutcommandinput.html
 */
export async function adminUserSignOut(username) {
    /*  Signs out users from all devices, as an administrator. It also invalidates all refresh tokens issued to a user.
        The user's current access and id tokens remain valid until their expiry. Access and id tokens expire one hour after they're issued.
     */
    const params = {
        UserPoolId: config.aws_user_pools_id,
        Username: username,
    };
    const command = new AdminUserGlobalSignOutCommand(params);
    return cognitoIdentityProviderClient().then(client => client.send(command))
}
